(function () {
    'use strict';

    angular
        .module('vatUtils', [
            'ngStorage',
            // 'tmh.dynamicLocale',
            // 'pascalprecht.translate',
            'ngResource',
            // 'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'angularFileUpload',
            // 'ngFileUpload',
            'ui.bootstrap',
            // 'ui.bootstrap.datetimepicker',
            'ui.router',
            // 'infinite-scroll',
            'angular-loading-bar',
            // 'selectionModel',
            'toaster',
            // 'ae-datetimepicker',
            'moment-picker',
            // 'xeditable',
            // 'amo.multiselect',
            'daterangepicker',
            // 'angular.filter',
            'ngSanitize',
            // 'checklist-model',
            'angularMoment',
            // 'angularPromiseButtons',
            // 'nya.bootstrap.select'
            // 'ngLetterAvatar',
            // 'angularDurationFormat'
        ])
        .config(modalConfig)
        .run(run);

    run.$inject = ['amMoment', '$state', '$rootScope', '$location', 'stateHandler'];
    modalConfig.$inject = ['$uibModalProvider'];

    function run(amMoment, $state, $rootScope, $location, stateHandler) {
        stateHandler.initialize();
        // translationHandler.initialize();
        // editableThemes.bs3.inputClass = 'input-sm';
        // editableThemes.bs3.buttonsClass = 'btn-sm';
        // editableOptions.theme = 'bs3';
        amMoment.changeLocale("pl");
        // editableOptions.blurElem = 'submit';

        $rootScope.isCurrentState = function (state) {
            return $state.is(state);
        };

        $rootScope.isActivePath = function (path) {
            return $location.path() === path;
        };
    }

    function modalConfig($uibModalProvider){
        $uibModalProvider.options.animation = false;
    }
})();
